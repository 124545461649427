import "./App.css";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import ContactPage from "./pages/ContactPage";
import TestingPage from "./pages/TestingPage";
import { WixPage } from "./components/WixPage";
import NotFoundPage from "./pages/NotFoundPage";
import CommunityPage from "./pages/CommunityPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

const PageWrapper = () => {
  // Made because useLocation can only be used inside <Router />
  const activePage = useLocation().pathname;
  const headerExcludePages = ["/premium", "/premium-in", "/remind"];
  const showHeader = !headerExcludePages.includes(activePage);

  return (
    <>
      {showHeader && <Header />}
      <Routes>
        {/* Website Pages */}
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/testing" element={<TestingPage />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        {/* Wix Pages */}
        <Route path="/premium" element={<WixPage url="premium" />} />
        <Route path="/premium-in" element={<WixPage url="premium/india" />} />
        <Route path="/remind" element={<WixPage url="remind" mode="old" marginTop={-40} />} />
      </Routes>
    </>
  );
};

// Complete App
const App = () => {
  return (
    <Router>
      <PageWrapper />
    </Router>
  );
};

export default App;
