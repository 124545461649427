import React, { useState, useRef, useEffect } from "react";

const AnimatedImage = ({ src, style }) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing once visible
          }
        });
      },
      { threshold: 0.4 } // Trigger when 10% of the image is visible
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const imageStyles = {
    opacity: isVisible ? 1 : 0,
    transition: "all 0.5s ease-out",
    transform: isVisible ? "translateY(0)" : "translateY(100px)",
    ...style,
  };

  return (
    <img src={src} ref={imgRef} style={imageStyles} alt="Feature" className="responsive-image" />
  );
};

export default AnimatedImage;
