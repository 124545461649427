import React, { useRef, useState, useEffect } from "react";
import Section from "../components/Section";
import { videoUrl } from "../utils/Constants";
import AppReviews from "../components/AppReviews";
import Colors, { hexToRGBA } from "../utils/Colors";
import PrimaryButton from "../components/PrimaryButton";
import AppScreenshots from "../components/AppScreenshots";
import { goToPlayStore } from "../functions/goToPlayStore";
import { useLocation, useNavigate } from "react-router-dom";
import { ContactSection, HelpSection } from "./ContactPage";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";
import landingBackground from "../images/landingBackground.webp";

const appReviews = [
  require("../images/Reviews/1.jpg"),
  require("../images/Reviews/2.jpg"),
  require("../images/Reviews/3.jpg"),
  require("../images/Reviews/4.jpg"),
  require("../images/Reviews/5.jpg"),
  require("../images/Reviews/6.jpg"),
  require("../images/Reviews/7.jpg"),
  require("../images/Reviews/8.jpg"),
  require("../images/Reviews/9.jpg"),
  require("../images/Reviews/10.jpg"),
  require("../images/Reviews/11.jpg"),
  require("../images/Reviews/12.jpg"),
];

const appScreenshots = [
  require("../images/Missions 1.png"),
  require("../images/Missions 2.png"),
  require("../images/Inspire 1.png"),
  require("../images/Inspire 2.png"),
  require("../images/Manage Me.png"),
  require("../images/Reminder.png"),
  require("../images/My Life 1.png"),
  require("../images/My Life 2.png"),
];

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reviewsRef = useRef(null);
  const benefitsRef = useRef(null);
  const isMobileDevice = useIsMobileDevice();

  // Handle Video Play and Pause
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle Initial Scrolling
  useEffect(() => {
    if (location.state?.scrollTo === "reviews" && reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
      navigate(location.pathname, { replace: true });
    }
    if (location.state?.scrollTo === "benefits" && reviewsRef.current) {
      benefitsRef.current.scrollIntoView({ behavior: "smooth" });
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, location.pathname, navigate]);

  // Star Icon
  const starIconStyles = {
    marginLeft: 5,
    width: isMobileDevice ? 30 : 38,
    height: isMobileDevice ? 30 : 38,
  };

  // Video Container
  const videoStylesContainer = {
    cursor: "pointer",
    aspectRatio: "auto",
    position: "relative",
    marginTop: isMobileDevice ? 50 : 0,
    marginBottom: isMobileDevice ? 20 : 15,
  };

  // Video Styles
  const videoStyles = {
    maxWidth: "90vw",
    maxHeight: "55vh",
    borderRadius: 20,
    aspectRatio: "auto",
  };

  // Icon Container Styles
  const playIconSide = isMobileDevice ? 60 : 80;
  const playIconStyles = {
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    width: playIconSide,
    height: playIconSide,
    borderRadius: playIconSide,
    left: `calc(50% - ${playIconSide / 2}px)`,
    backgroundColor: hexToRGBA(Colors.backgroundPrimary, 0.3),
    top: isMobileDevice ? `calc(50% - ${playIconSide / 2}px)` : "40%",
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      {/* Introduction */}
      <Section background={landingBackground}>
        <div style={topSectionContainer}>
          <h3 style={{ marginTop: isMobileDevice ? 40 : 30, maxWidth: "90%" }}>
            Complete Personal Growth App with AI
          </h3>
          <div style={videoStylesContainer}>
            <video
              ref={videoRef}
              style={videoStyles}
              onClick={handlePlayPause}
              poster={require("../images/Thumbnail.png")}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
            {!isPlaying && (
              <div style={playIconStyles}>
                <img
                  alt="Play Icon"
                  src={require("../images/PlayIcon.png")}
                  style={{
                    marginLeft: "5px",
                    width: isMobileDevice ? 20 : 25,
                    height: isMobileDevice ? 20 : 25,
                  }}
                ></img>
              </div>
            )}
          </div>

          <h4 style={{ maxWidth: "85%" }}>We believe every human must have an organized life</h4>
          {!isMobileDevice && (
            <PrimaryButton
              width="40%"
              padding="10px"
              marginBottom="20px"
              borderRadius="25px"
              onClick={goToPlayStore}
              title="Download App to Grow"
            />
          )}
        </div>
      </Section>

      {/* Reviews */}
      <div ref={reviewsRef}></div>
      <Section>
        <h2>
          50+ 5
          <img alt="Star Icon" style={starIconStyles} src={require("../images/StarIcon.png")}></img>{" "}
          reviews
        </h2>
        <h4 style={{ color: Colors.disabledWhite, marginTop: -20, marginBottom: 50 }}>
          with avg rating 4.98
        </h4>
        <AppReviews imageUrls={appReviews} />
      </Section>

      {/* Benefits */}
      <div ref={benefitsRef}></div>
      <Section>
        <h2>Platform designed to</h2>
        <AppScreenshots imageUrls={appScreenshots} />
      </Section>

      {/* Contact */}
      <ContactSection />
      <HelpSection />

      {/* Download Button Pin */}
      {isMobileDevice && (
        <div style={mobileDownloadContainer}>
          <div style={mobileDownloadButton} onClick={goToPlayStore}>
            <h3 style={{ fontSize: "20px" }}>Download App to Grow</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;

const topSectionContainer = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "calc(100vh - 80px)",
};

const mobileDownloadContainer = {
  bottom: 0,
  zIndex: 1000,
  width: "100%",
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px 0px 12px 0px",
  backgroundColor: Colors.backgroundPrimary,
};

const mobileDownloadButton = {
  width: "90%",
  display: "flex",
  borderRadius: 40,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: Colors.livornPrimary,
};
