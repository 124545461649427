const NotFoundPage = () => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}
    >
      <h2>Error 404, Page Not Found</h2>
    </div>
  );
};

export default NotFoundPage;
