import AnimatedImage from "./AnimatedImage";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";

const AppScreenshots = ({ imageUrls }) => {
  const isMobileDevice = useIsMobileDevice();
  const margin = isMobileDevice ? "40px" : "50px";

  const imageStyle = {
    margin,
    height: "auto",
    maxWidth: "85vw",
    maxHeight: "80vh",
  };

  return (
    <div style={imageContainerStyle}>
      {imageUrls.map((url, index) => (
        <AnimatedImage src={url} key={index} style={imageStyle} />
      ))}
    </div>
  );
};

export default AppScreenshots;

const imageContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
};
