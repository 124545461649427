import DisplaySection from "../components/DisplaySection";
import { whatsappCommunityUrl } from "../utils/Constants";

const CommunityPage = () => {
  return (
    <div style={{ paddingTop: 50 }}>
      <DisplaySection
        heading="Community"
        mobileImageWidth="50vw"
        marginLeft={120}
        buttonTitle="Whatsapp Group"
        imageSource={require("../assets/WhatsappIcon.webp")}
        onButtonClick={() => window.open(whatsappCommunityUrl, "_blank")}
        imageStyles={{ height: "25vw", width: "auto", marginLeft: 40 }}
        text="Grow together with everyone in our WhatsApp Community."
        description=" Join our Community on WhatsApp to be the first to participate to discuss, feedbacks, improve Livorn and yourself."
      />
    </div>
  );
};

export default CommunityPage;
