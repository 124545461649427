import { contactEmail } from "../utils/Constants";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";

const PrivacyPolicyPage = () => {
  const isMobileDevice = useIsMobileDevice();

  return (
    <div style={{ padding: isMobileDevice ? "60px 30px" : "60px 80px" }}>
      <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
      <p style={{ marginTop: -15, textAlign: "center" }}>Effective Date 31-08-2023</p>
      <h2>Your Privacy is important to us</h2>

      <p>
        It is Cubes's policy to respect your privacy regarding any information we may collect while
        operating our website. This Privacy Policy applies to www.livorn.in/ (hereinafter, "us",
        "we", or "www.livorn.in/"). We respect your privacy and are committed to protecting
        personally identifiable information you may provide us through the Website. We have adopted
        this privacy policy ("Privacy Policy") to explain what information may be collected on our
        Website, how we use this information, and under what circumstances we may disclose the
        information to third parties. This Privacy Policy applies only to information we collect
        through the Website and does not apply to our collection of information from other sources.
        <br />
        This Privacy Policy, together with the Terms of service posted on our Website, set forth the
        general rules and policies governing your use of our Website. Depending on your activities
        when visiting our Website, you may be required to agree to additional terms of service.
      </p>

      {/* Contents */}
      <h2>Contents</h2>
      <h4>
        1. Website Visitors
        <br />
        2. Security
        <br />
        3. Aggregated Statistics
        <br />
        4. Cookies
        <br />
        5. E-commerce
        <br />
        6. Privacy Policy Changes
        <br />
        7. Contact Information & Credit
      </h4>

      {/* First */}
      <h2>1. Website Visitors</h2>
      <p>
        Like most website operators, Cubes collects non-personally-identifying information of the
        sort that web browsers and servers typically make available, such as the browser type,
        language preference, referring site, and the date and time of each visitor request. Cubes's
        purpose in collecting non-personally identifying information is to better understand how
        Cubes's visitors use its website. From time to time, Cubes may release
        non-personally-identifying information in the aggregate, e.g., by publishing a report on
        trends in the usage of its website.
        <br />
        Cubes also collects potentially personally-identifying information like Internet Protocol
        (IP) addresses for logged in users and for users leaving comments on https://www.livorn.in/
        blog posts. Cubes only discloses logged in user and commenter IP addresses under the same
        circumstances that it uses and discloses personally-identifying information as described
        below.
      </p>

      {/* Second */}
      <h2>2. Security</h2>
      <p>
        The security of your Personal Information is important to us, but remember that no method of
        transmission over the Internet, or method of import {useIsMobileDevice} from
        './../utils/useIsMobileDevice'; electronic storage is 100% secure. While we strive to use
        commercially acceptable means to protect your Personal Information, we cannot guarantee its
        absolute security.
      </p>

      {/* Third */}
      <h2>3. Aggregated Statistics</h2>
      <p>
        Cubes may collect statistics about the behavior of visitors to its website. Cubes may
        display this information publicly or provide it to others. However, Cubes does not disclose
        your personally-identifying information.
      </p>

      {/* Fourth */}
      <h2>4. Cookies</h2>
      <p>
        To enrich and perfect your online experience, Cubes uses "Cookies", similar technologies and
        services provided by others to display personalized content, appropriate advertising and
        store your preferences on your computer. A cookie is a string of information that a website
        stores on a visitor's computer, and that the visitor's browser provides to the website each
        time the visitor returns. Cubes uses cookies to help Cubes identify and track visitors,
        their usage of https://www.livorn.in/, and their website access preferences. Cubes visitors
        who do not wish to have cookies placed on their computers should set their browsers to
        refuse cookies before using Cubes's websites, with the drawback that certain features of
        Cubes's websites may not function properly without the aid of cookies.
        <br />
        By continuing to navigate our website without changing your cookie settings, you hereby
        acknowledge and agree to Cubes's use of cookies.
      </p>

      {/* Fifth */}
      <h2>5. E-commerce</h2>
      <p>
        Those who engage in transactions with Cubes – by purchasing Cubes's services or products,
        are asked to provide additional information, including as necessary the personal and
        financial information required to process those transactions. In each case, Cubes collects
        such information only insofar as is necessary or appropriate to fulfill the purpose of the
        visitor's interaction with Cubes. Cubes does not disclose personally-identifying information
        other than as described below. And visitors can always refuse to supply
        personally-identifying information, with the caveat that it may prevent them from engaging
        in certain website-related activities.
      </p>

      {/* Sixth */}
      <h2>6. Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, Cubes may change its Privacy Policy from time
        to time, and in Cubes's sole discretion. Cubes encourages visitors to frequently check this
        page for any changes to its Privacy Policy. Your continued use of this site after any change
        in this Privacy Policy will constitute your acceptance of such change.
      </p>

      {/* Seventh */}
      <h2>7. Contact Information and Credit</h2>
      <p>
        This privacy policy was created at privacyterms.io privacy policy generator. If you have any
        questions about our Privacy Policy, please contact us via email at {contactEmail}.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
