import Colors from "../utils/Colors";
import PrimaryButton from "./PrimaryButton";
import { useIsMobileDevice } from "./../utils/useIsMobileDevice";

const DisplaySection = ({
  text,
  heading,
  buttonTitle,
  description,
  imageSource,
  imageStyles,
  onButtonClick,
  reverse = false,
  mobileImageWidth = "70vw",
  ...external
}) => {
  const isMobileDevice = useIsMobileDevice();
  const flexDirection = reverse ? "row-reverse" : "row";

  if (!isMobileDevice) {
    return (
      <div style={{ display: "flex", alignItems: "center", flexDirection, ...external }}>
        <div style={{ padding: "100px 100px", width: "30%" }}>
          <h1>{heading}</h1>
          <h4>
            {text}
            <span style={{ color: Colors.disabledWhite }}>{description}</span>
          </h4>

          {buttonTitle && <PrimaryButton title={buttonTitle} onClick={onButtonClick} />}
        </div>
        <img src={imageSource} className="responsive-image" style={imageStyles} alt="" />
      </div>
    );
  }

  if (isMobileDevice) {
    return (
      <div style={{ padding: "40px" }}>
        {/* TODO => Later put this image at center */}
        <img
          src={imageSource}
          className="responsive-image"
          style={{ width: mobileImageWidth }}
          alt=""
        />
        <h1>{heading}</h1>
        <h4>
          {text}
          <span style={{ color: Colors.disabledWhite }}>{description}</span>
        </h4>

        {buttonTitle && <PrimaryButton title={buttonTitle} onClick={onButtonClick} width="100%" />}
      </div>
    );
  }
};

export default DisplaySection;
