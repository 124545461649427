// Button Component
import { useState } from "react";
import Colors from "../utils/Colors";

const PrimaryButton = ({ title, onClick, ...props }) => {
  const [backgroundColor, setBackgroundColor] = useState(Colors.livornPrimary);

  const buttonStyles = {
    height: 45,
    width: "80%",
    marginTop: 25,
    display: "flex",
    backgroundColor,
    borderRadius: 50,
    cursor: "pointer",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: "15px",
    ...props,
  };

  return (
    <div
      onClick={onClick}
      style={buttonStyles}
      onMouseLeave={() => setBackgroundColor(Colors.livornPrimary)}
      onMouseEnter={() => setBackgroundColor(Colors.livornSecondary)}
    >
      <h4>{title}</h4>
    </div>
  );
};

export default PrimaryButton;
