import { contactEmail } from "../utils/Constants";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";

const TermsOfUsePage = () => {
  const isMobileDevice = useIsMobileDevice();

  return (
    <div style={{ padding: isMobileDevice ? "60px 30px" : "60px 80px" }}>
      <h1 style={{ textAlign: "center" }}>Terms Of Use</h1>
      <h2>Welcome to Cubes</h2>

      <p>
        These terms of service outline the rules and regulations for the use of Cubes Website.
        <br />
        By accessing this website we assume you accept these terms of service in full. Do not
        continue to use Cubes website if you do not accept all of the terms of service stated on
        this page.
        <br />
        The following terminology applies to these Terms of Service, Privacy Statement and
        Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the
        person accessing this website and accepting the Company's terms of service. "The Company",
        "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us",
        refers to both the Client and ourselves, or either the Client or ourselves. All terms refer
        to the offer, acceptance and consideration of payment necessary to undertake the process of
        our assistance to the Client in the most appropriate manner, whether by formal meetings of a
        fixed duration, or any other means, for the express purpose of meeting the Client's needs in
        respect of provision of the Company's stated services/products, in accordance with and
        subject to, prevailing law of . Any use of the above terminology or other words in the
        singular, plural, capitalization and/or he/she or they, are taken as interchangeable and
        therefore as referring to same.
      </p>

      {/* Contents */}
      <h2>Contents</h2>
      <h4>
        1. Cookies
        <br />
        2. License
        <br />
        3. User Comments
        <br />
        4. Hyperlinking to our Content
        <br />
        5. Iframes
        <br />
        6. Content Liability
        <br />
        7. Reservation of Rights
        <br />
        8. Removal of Links from our website
        <br />
        9. Disclaimer
        <br />
        10. Credit and Contact Information
      </h4>

      {/* First */}
      <h2>1. Cookies</h2>
      <p>
        We employ the use of cookies. By using Cubes website you consent to the use of cookies in
        accordance with Cubes privacy policy.
        <br />
        Most of the modern day interactive web sites use cookies to enable us to retrieve user
        details for each visit. Cookies are used in some areas of our site to enable the
        functionality of this area and ease of use for those people visiting. Some of our affiliate
        / advertising partners may also use cookies.
      </p>

      {/* Second */}
      <h2>2. License</h2>
      <p>
        Unless otherwise stated, Cubes and/or it's licensors own the intellectual property rights
        for all material on Cubes. All intellectual property rights are reserved. You may view
        and/or print pages from www.cubes.company/ for your own personal use subject to restrictions
        set in these terms of service.
        <br />
        <br /> You must not:
        <br />
        1. Republish material from www.cubes.company/ <br />
        2. Sell, rent or sub-license material from www.cubes.company/
        <br />
        3. Reproduce, duplicate or copy material from www.cubes.company/
        <br />
        <br />
        Redistribute content from Cubes (unless content is specifically made for redistribution).
      </p>

      {/* Third */}
      <h2>3. User Comments</h2>
      <p>
        This Agreement shall begin on the date hereof. Certain parts of this website offer the
        opportunity for users to post and exchange opinions, information, material and data
        ('Comments') in areas of the website. Cubes does not screen, edit, publish or review
        Comments prior to their appearance on the website and Comments do not reflect the views or
        opinions of Cubes, its agents or affiliates. Comments reflect the view and opinion of the
        person who posts such view or opinion. To the extent permitted by applicable laws Cubes
        shall not be responsible or liable for the Comments or for any loss cost, liability, damages
        or expenses caused and or suffered as a result of any use of and/or posting of and/or
        appearance of the Comments on this website.
        <br />
        Cubes reserves the right to monitor all Comments and to remove any Comments which it
        considers in its absolute discretion to be inappropriate, offensive or otherwise in breach
        of these Terms of Service.
        <br />
        You warrant and represent that:
        <br />
        1. You are entitled to post the Comments on our website and have all necessary licenses and
        consents to do so; <br />
        2. The Comments do not infringe any intellectual property right, including without
        limitation copyright, patent or trademark, or other proprietary right of any third party;{" "}
        <br />
        3. The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise
        unlawful material or material which is an invasion of privacy <br />
        4. The Comments will not be used to solicit or promote business or custom or present
        commercial activities or unlawful activity.
        <br />
        You hereby grant to Cubes a non-exclusive royalty-free license to use, reproduce, edit and
        authorize others to use, reproduce and edit any of your Comments in any and all forms,
        formats or media.
      </p>

      {/* Fourth */}
      <h2>4. Hyperlinking to out Content</h2>
      <p>
        The following organizations may link to our Web site without prior written approval:
        Government agencies, Search engines, News organizations. Online directory distributors when
        they list us in the directory may link to our Web site in the same manner as they hyperlink
        to the Web sites of other listed businesses; and Systemwide Accredited Businesses except
        soliciting non-profit organizations, charity shopping malls, and charity fundraising groups
        which may not hyperlink to our Web site.
        <br />
        These organizations may link to our home page, to publications or to other Web site
        information so long as the link: (a) is not in any way misleading; (b) does not falsely
        imply sponsorship, endorsement or approval of the linking party and its products or
        services; and (c) fits within the context of the linking party's site. We may consider and
        approve in our sole discretion other link requests from the following types of
        organizations: commonly-known consumer and/or business information sources such as Chambers
        of Commerce, American Automobile Association, AARP and Consumers Union, dot.com community
        sites, associations or other groups representing charities, including charity giving sites,
        online directory distributors, internet portals, accounting, law and consulting firms whose
        primary clients are businesses, and educational institutions and trade associations. <br />
        We will approve link requests from these organizations if we determine that: (a) the link
        would not reflect unfavorably on us or our accredited businesses (for example, trade
        associations or other organizations representing inherently suspect types of business, such
        as work-at-home opportunities, shall not be allowed to link); (b)the organization does not
        have an unsatisfactory record with us; (c) the benefit to us from the visibility associated
        with the hyperlink outweighs the absence of Cubes; and (d) where the link is in the context
        of general resource information or is otherwise consistent with editorial content in a
        newsletter or similar product furthering the mission of the organization.
        <br />
        These organizations may link to our home page, to publications or to other Web site
        information so long as the link: (a) is not in any way misleading; (b) does not falsely
        imply sponsorship, endorsement or approval of the linking party and it products or services;
        and (c) fits within the context of the linking party's site.
        <br />
        If you are among the organizations listed in paragraph 2 above and are interested in linking
        to our website, you must notify us by sending an e-mail to contact@cubes.company. Please
        include your name, your organization name, contact information (such as a phone number
        and/or e-mail address) as well as the URL of your site, a list of any URLs from which you
        intend to link to our Web site, and a list of the URL(s) on our site to which you would like
        to link. Allow 2-3 weeks for a response.
        <br />
        Approved organizations may hyperlink to our Web site as follows:
        <br />
        1. By use of our corporate name, or
        <br />
        2. By use of the uniform resource locator (Web address) being linked to, or
        <br />
        3. By use of any other description of our Web site or material being linked to that makes
        sense within the context and format of content on the linking party's site.
        <br />
        <br />
        No use of Cubes logo or other artwork will be allowed for linking absent a trademark license
        agreement.
      </p>

      {/* Fifth */}
      <h2>5. Iframes</h2>
      <p>
        Without prior approval and express written permission, you may not create frames around our
        Web pages or use other techniques that alter in any way the visual presentation or
        appearance of our Web site.
      </p>

      {/* Sixth */}
      <h2>6. Content Liability</h2>
      <p>
        We shall have no responsibility or liability for any content appearing on your Web site. You
        agree to indemnify and defend us against all claims arising out of or based upon your
        Website. No link(s) may appear on any page on your Web site or within any context containing
        content or materials that may be interpreted as libelous, obscene or criminal, or which
        infringes, otherwise violates, or advocates the infringement or other violation of, any
        third party rights.
      </p>

      {/* Seventh */}
      <h2>7. Reservation of Rights</h2>
      <p>
        We reserve the right at any time and in its sole discretion to request that you remove all
        links or any particular link to our Web site. You agree to immediately remove all links to
        our Web site upon such request. We also reserve the right to amend these terms of service
        and its linking policy at any time. By continuing to link to our Web site, you agree to be
        bound to and abide by these linking terms of service.
      </p>

      {/* Eighth */}
      <h2>8. Removal of Links from our Website</h2>
      <p>
        If you find any link on our Web site or any linked web site objectionable for any reason,
        you may contact us about this. We will consider requests to remove links but will have no
        obligation to do so or to respond directly to you.
        <br />
        Whilst we endeavor to ensure that the information on this website is correct, we do not
        warrant its completeness or accuracy; nor do we commit to ensuring that the website remains
        available or that the material on the website is kept up to date.
      </p>

      {/* Ninth */}
      <h2>9. Disclaimer</h2>
      <p>
        To the maximum extent permitted by applicable law, we exclude all representations,
        warranties and conditions relating to our website and the use of this website (including,
        without limitation, any warranties implied by law in respect of satisfactory quality,
        fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer
        will:
        <br />
        1. limit or exclude our or your liability for death or personal injury resulting from
        negligence.
        <br />
        2. limit or exclude our or your liability for fraud or fraudulent misrepresentation.
        <br />
        3. limit any of our or your liabilities in any way that is not permitted under applicable
        law.
        <br />
        4. exclude any of our or your liabilities that may not be excluded under applicable law.
        <br />
        <br />
        The limitations and exclusions of liability set out in this Section and elsewhere in this
        disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities
        arising under the disclaimer or in relation to the subject matter of this disclaimer,
        including liabilities arising in contract, in tort (including negligence) and for breach of
        statutory duty.
        <br />
        To the extent that the website and the information and services on the website are provided
        free of charge, we will not be liable for any loss or damage of any nature.
      </p>

      {/* Tenth */}
      <h2>10. Contact Information and Credit</h2>
      <p>
        This Terms of service page was created at privacyterms.io terms & conditions generator. If
        you have any queries regarding any of our terms, please contact us at {contactEmail}
      </p>
    </div>
  );
};

export default TermsOfUsePage;
