import React from "react";

const Section = ({ background, children, ...external }) => {
  const sectionStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // display: "flex",
    // minHeight: "100vh",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 0,
    paddingTop: 80,
  };

  return <div style={{ ...sectionStyle, ...external }}>{children}</div>;
};

export default Section;
