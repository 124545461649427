import React from "react";

const LivornLogo = ({ scale = 0.1, ...props }) => (
  <svg
    fill="none"
    width={886 * scale}
    height={886 * scale}
    viewBox="0 0 886 886"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#1B98F5"
      d="M865.566 270.67C868.574 277.93 871.581 285.213 873.929 292.827C711.463 -170.346 -0.0834798 -56.5112 0.189771 440.78C-10.2381 785.918 411.396 1008.15 695.326 813.59C795.602 744.876 731.794 680.662 664.995 729.34C439.113 903.976 77.2245 727.775 86.5593 440.78C79.957 130.44 481.511 -36.0448 701.869 182.891C940.595 401.243 681.789 727.125 459.119 508.263C466.733 490.543 494.996 421.458 580.191 210.017L516.679 160.748L401.803 443.844C400.03 448.127 397.807 453.443 396.633 456.27C331.821 407.356 216.716 454.122 218.865 537.841C218.865 647.637 387.985 676.646 434.467 578.448C537.793 684.265 749.075 680.677 839.47 552.326C889.43 499.211 900.8 340.42 865.566 270.67ZM501.273 199.038C500.289 201.455 499.324 203.849 498.379 206.222C499.334 203.844 500.299 201.45 501.273 199.038Z"
    />
    <path
      fill="#1B98F5"
      d="M870.028 281.015C872.601 287.472 874.443 292.804 874.141 292.924C873.84 293.044 871.51 287.906 868.937 281.449C866.364 274.992 864.522 269.661 864.824 269.541C865.125 269.421 867.455 274.558 870.028 281.015Z"
    />
  </svg>
);

export default LivornLogo;
