// To use wix pages on website

import { useState, useEffect } from "react";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";

export const WixPage = ({ url, mode = "new" }) => {
  const isMobileDevice = useIsMobileDevice();

  const [marginTop, setMarginTop] = useState(-30);
  useEffect(() => {
    if (mode === "old" && isMobileDevice) {
      setMarginTop(-40);
    } else if (mode === "old" && !isMobileDevice) {
      setMarginTop(-50);
    }
  }, [mode, isMobileDevice]);

  return (
    <div
      style={{
        marginTop,
        width: "100vw",
        overflow: "hidden",
        height: "calc(100vh + 30px)",
      }}
    >
      <iframe
        title="Livorn Wix Page"
        src={"https://lvlupharshit.wixsite.com/" + url}
        style={{ height: "100%", width: "100%", border: "none" }}
      ></iframe>
    </div>
  );
};
