import React from "react";
import AnimatedImage from "./AnimatedImage";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";

const AppReviews = ({ imageUrls }) => {
  const isMobileDevice = useIsMobileDevice();
  const width = isMobileDevice ? "90%" : "30%";

  const imageStyle = {
    width,
    margin: "20px",
    height: "auto",
    borderRadius: 20,
  };

  return (
    <div style={imageContainerStyle}>
      {imageUrls.map((url, index) => (
        <AnimatedImage src={url} key={index} style={imageStyle} />
      ))}
    </div>
  );
};

export default AppReviews;

const imageContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: "center",
};
