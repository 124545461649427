import React from "react";

const TestingPage = () => {
  return (
    <div style={{ height: "100vh", width: "100vw", overflow: "hidden", marginTop: -30 }}>
      <iframe
        title="Livorn Premium Landing Page"
        src="https://lvlupharshit.wixsite.com/my-site-3"
        style={{ height: "100%", width: "100%", border: "none" }}
      ></iframe>
    </div>
  );
};

export default TestingPage;
